@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100%;
  flex-direction: column;
  --tw-bg-opacity: 1;
}

#root {
    height: 100%;
}

.placeholder {
    height: 46vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-container {
    opacity: 70%;
    z-index: 11;
}

.status-container .green svg {
    color: green
}

.status-container .red svg {
    color: red
}

.status-container .yellow svg {
    color: yellow
}

.status-icon {
    height:200px;
    width: 200px;
}

.scanner {
    width: 92vw;
    height: 46vh;
    object-fit: cover;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
}

.flip-webcam {
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
}

.ais-SearchBox-input {
    width: 100%;
    border-color: rgb(209 213 219);
    border-radius: 0.25rem;
    background-color: #f5f5f5;
    color: #171717
}

.sidebar-logo {
    content: url('./images/full-logo.png')
}

.sidebar-icon {
    content: url('./images/icon-only-h96.png')
}

@media (prefers-color-scheme: dark) {
    .ais-SearchBox-input {
        background-color: #171717;
        color: #f5f5f5;
    }
    .sidebar-logo {
        content: url('./images/full-logo-for-dark-mode.png')
    }
}

@keyframes slideDown {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .enterAnimation {
    animation: slideDown 0.5s ease forwards;
  }

.hidden {
    display: none;
}

@keyframes fadeInScaleUp {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    50% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOutScaleDown {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}

.fade-in-scale-up {
    animation: fadeInScaleUp 0.2s forwards;
}

.fade-out-scale-down {
    animation: fadeOutScaleDown 0.5s forwards;
}